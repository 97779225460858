<template>
    <!--应聘-->
    <div>
        <el-dialog title="详细信息" :visible.sync="dlgVisible" v-model="dlgVisible" :close-on-click-modal="false" width="80%">
            <el-tabs type="border-card">
                <el-tab-pane>
                    <span slot="label" ref="print"> 人员详情信息</span>
                    <div id="printMe" ref="printContent">
                        <el-form ref="dlgFrom" label-width="100px" :model="dlgFrom" size="mini">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="ApplyDate" label="填表日期">
                                        <el-date-picker type="date" v-model="dlgFrom.ApplyDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="头像" style="text-align: right">
                                        <el-upload class="avatar-uploader" action="https://api.gradgroup.cn/ftp/ftp/upload" :headers ="token":show-file-list="false" :on-success="OSOnSuccess">
                                            <img v-if="dlgFrom.Image_URL" v-lazy="dlgFrom.Image_URL" class="avatar" style="width: 150px;" />
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="姓名:" prop="Name">
                                        <el-input v-model="dlgFrom.Name" :disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="身份证号:" prop="IDCard">
                                        <el-input v-model="dlgFrom.IDCard" :disabled="true" maxlength="18" show-word-limit placeholder="请输入有效身份证号" @blur="nocheck"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="出生年月:" prop="Birthday">
                                        <el-date-picker type="date" v-model="dlgFrom.Birthday" value-format="yyyy-MM-dd" placeholder="选择出生年月" :disabled="true"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="性别:">
                                        <el-radio-group v-model="dlgFrom.Sex" :disabled="true">
                                            <el-radio label="男"></el-radio>
                                            <el-radio label="女"></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="民族:" prop="Nationality">
                                        <el-input v-model="dlgFrom.Nationality"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="政治面貌:" prop="PoliticsStatus">
                                        <el-select v-model="dlgFrom.PoliticsStatus" placeholder="选择政治面貌">
                                            <el-option v-for="item in poTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>




                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="婚姻状况:" prop="MarriedStatus">
                                        <el-select v-model="dlgFrom.MarriedStatus" placeholder="选择婚姻状况">
                                            <el-option v-for="item in qyTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="职称证书:" prop="Position">
                                        <el-select v-model="dlgFrom.Position" placeholder="选择职称">
                                            <el-option v-for="item in tiTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="技能证书:" prop="skillCert">
                                        <el-select v-model="dlgFrom.skillCert"
                                                   placeholder="选择技能证书">
                                            <el-option v-for="item in jnTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                        <!--<el-input v-model="dlgFrom.skillCert"></el-input>-->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="全日制学历 :" prop="FirstDiploma">
                                        <el-select v-model="dlgFrom.FirstDiploma" placeholder="选择第一学历">
                                            <el-option v-for="item in edTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="非全日制学历:" prop="TopDiploma">
                                        <el-select v-model="dlgFrom.TopDiploma" placeholder="选择非全日制学历">
                                            <el-option v-for="item in edTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="外语语种及级别:">
                                        <el-input v-model="dlgFrom.LanguageLevel"></el-input>
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="籍贯:" prop="NativePlace">
                                        <el-input v-model="dlgFrom.NativePlace"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="户籍地址:" prop="IDAddress">
                                        <el-input v-model="dlgFrom.IDAddress" placeholder="请输入户籍地址"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="户口性质:" prop="NativeStatus">
                                        <el-select v-model="dlgFrom.NativeStatus" placeholder="选择户口性质">
                                            <el-option v-for="item in adTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="通讯地址:" prop="Address">
                                        <el-input v-model="dlgFrom.Address" placeholder="请输入通讯地址"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="手机号码:" prop="MobilePhone">
                                        <el-input v-model="dlgFrom.MobilePhone" maxlength="11" show-word-limit placeholder="请输入有效手机号" @blur="phonecheck"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="电子邮箱:">
                                        <el-input v-model="dlgFrom.Email" placeholder="请输入电子邮箱"></el-input>
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="招聘来源:">
                                        <el-select v-model="dlgFrom.ApplySource" placeholder="选择招聘来源">
                                            <el-option v-for="item in applyTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="应聘岗位:">
                                        <el-input v-model="dlgFrom.ApplyJob" placeholder="请输入应聘岗位"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col class="inp-con" :span="8">
                                    <el-form-item label="介绍人:">
                                        <el-input v-model="dlgFrom.IntroducerName" placeholder="介绍人"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="紧急联系人/姓名:" label-width="140px">
                                        <el-input v-model="dlgFrom.Emergency"
                                                  placeholder="请输入紧急联系人/姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="紧急联系人/电话:" label-width="140px">
                                        <el-input v-model="dlgFrom.EmergencyPhone" placeholder="请输入紧急联系人/电话"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="可开始工作时间:" label-width="140px">
                                        <el-date-picker type="date" v-model="dlgFrom.ApplyStartDate" value-format="yyyy-MM-dd" placeholder="选择可开始工作时间"></el-date-picker>
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="特殊人员:">
                                        <el-select v-model="dlgFrom.SpecialPersonnel"
                                                   multiple
                                                   placeholder="可多选"
                                                   style="width: 100%">
                                            <el-option label="退伍军人"
                                                       value="退伍军人"></el-option>
                                            <el-option label="失业人员"
                                                       value="失业人员"></el-option>
                                            <el-option label="残疾证"
                                                       value="残疾证"></el-option>
                                            <el-option label="无"
                                                       value="无"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="intro">
                                        <p>健康调查<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-table :data="dlgFrom.DiseaseArr" style="width: 100%" class="bg-color">
                                    <el-table-column prop="DiseaseType" label="病史类型"></el-table-column>
                                    <el-table-column prop="IsYes" label="状态"></el-table-column>
                                    <el-table-column prop="Explain" label="说明"></el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>学习经历<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                                <el-col :span="12"
                                        class="toolbar"
                                        style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn" type="primary" v-on:click="customerhandleAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="dlgFrom.EducatedArr" style="width: 100%">
                                    <el-table-column prop="EnterTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="GraduateTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="GradauteSchool" label="学校名称"></el-table-column>
                                    <el-table-column prop="Speciality" label="专业"></el-table-column>
                                    <el-table-column prop="Certificate" label="学历"></el-table-column>
                                    <el-table-column prop="Voucher" label="证明人及电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="CustomerhandleDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                            <el-button size="small" v-on:click="CustomerhandleDelete(scope.row)" type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>

                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>培训经历</p>
                                    </div>
                                </el-col>
                                <el-col :span="12" class="toolbar" style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn" type="primary" v-on:click="trainAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="dlgFrom.TrainedArr" style="width: 100%">
                                    <el-table-column prop="StartTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="EndTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="Institutions" label="培训机构"></el-table-column>
                                    <el-table-column prop="Details" label="培训内容"></el-table-column>
                                    <el-table-column prop="InCertificate" label="证书名称"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="TrainingExperienceDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                            <el-button size="small" v-on:click="TrainingExperienceDelete(scope.row)" type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-row>
                                    <el-col :span="12" class="list-title">
                                        <div class="intro">
                                            <p>工作经历<span style="color: red">*</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="12" class="toolbar" style="padding-bottom: 0px; text-align: right">
                                        <el-form :inline="true">
                                            <el-form-item>
                                                <el-button class="add-btn" type="primary" v-on:click="workAdd">新增</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-table :data="dlgFrom.WorkedArr" style="width: 100%">
                                    <el-table-column prop="StartTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="EndTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="WorkAddress" label="工作单位"></el-table-column>
                                    <el-table-column prop="LeavingReason" label="职位/岗位"></el-table-column>
                                    <el-table-column prop="BecaseWork" label="离职原因"></el-table-column>
                                    <el-table-column prop="WorkVoucher" label="证明人及电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="WorkExperienceDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                            <el-button size="small" v-on:click="WorkExperienceDelete(scope.row)" type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>家庭成员<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                                <el-col :span="12" class="toolbar" style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn" type="primary" v-on:click="familyAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="dlgFrom.FamilyArr" style="width: 100%">
                                    <el-table-column prop="FaName" label="姓名"></el-table-column>
                                    <el-table-column prop="Relationship" label="与本人关系"></el-table-column>
                                    <el-table-column prop="WorkDpt" label="职位/岗位"></el-table-column>
                                    <el-table-column prop="WorkUnits" label="工作单位"></el-table-column>
                                    <el-table-column prop="PhoneNo" label="联系电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="FamilyDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                            <el-button size="small" v-on:click="FamilyDelete(scope.row)" type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <span>备注:</span><el-input type="textarea"
                                                              v-model="dlgFrom.Remark"></el-input>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>知识产权情况调查<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <span>在原单位工作的岗位是否涉及知识产权:</span>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item label="">
                                        <el-switch v-model="dlgFrom.IsIPR"
                                                   active-text="是"
                                                   inactive-text="否">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <span>请具体列举收及的知识产权内容:</span>
                                    <el-input type="textarea" v-model="dlgFrom.IPRContent"></el-input>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <span>是否与原单位签订过竞业限制条款:</span>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item label="">
                                        <el-switch v-model="dlgFrom.IsAgreement"
                                                   active-text="是"
                                                   inactive-text="否">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <span>请写明竞业限制条款的生效时间和年限:</span>
                                    <el-input type="textarea" v-model="dlgFrom.AgreementContent"></el-input>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" style="text-align: center">
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('dlgFrom')">确认修改</el-button>
                                        <el-button @click="resetForm('dlgFrom')">取消</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="面试记录">
                    <el-table :data="InterviewRecord" @row-dblclick="onInterViewdblclick" highlight-current-row style="width: 100%">
                        <el-table-column prop="Factory" label="面试公司"> </el-table-column>
                        <el-table-column label="面试时间" prop="TalkDate" :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="面试岗位" prop="Post"></el-table-column>
                        <el-table-column prop="IsPassed" label="是否通过">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <el-dialog title="学习经历" :visible.sync="CustomeraddFormVisible" v-model="CustomeraddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="CustomeraddForm" :model="CustomeraddForm">
                <el-form-item label="开始时间:" prop="EnterTime">
                    <el-date-picker v-model="CustomeraddForm.EnterTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" prop="GraduateTime">
                    <el-date-picker v-model="CustomeraddForm.GraduateTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="学校名称:" prop="GradauteSchool">
                    <el-input v-model="CustomeraddForm.GradauteSchool"></el-input>
                </el-form-item>
                <el-form-item label="专业:">
                    <el-input v-model="CustomeraddForm.Speciality"></el-input>
                </el-form-item>
                <el-form-item label="学历:">
                    <el-input v-model="CustomeraddForm.Certificate"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话">
                    <el-input v-model="CustomeraddForm.Voucher"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="CustomeraddFormVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="CustomeraddSubmit('CustomeraddForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="培训经历" :visible.sync="TrainingExperienceaddFormVisible" v-model="TrainingExperienceaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="TrainaddForm" :model="TrainaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="TrainaddForm.StartTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="TrainaddForm.EndTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="培训机构:">
                    <el-input v-model="TrainaddForm.Institutions"></el-input>
                </el-form-item>
                <el-form-item label="培训内容:">
                    <el-input type="textarea" v-model="TrainaddForm.Details"></el-input>
                </el-form-item>
                <el-form-item label="证书名称:">
                    <el-input v-model="TrainaddForm.InCertificate"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="TrainingExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="TrainaddSubmit('TrainaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="工作经历" :visible.sync="WorkExperienceaddFormVisible" v-model="WorkExperienceaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="WorkaddForm" :model="WorkaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="WorkaddForm.StartTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="WorkaddForm.EndTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="工作单位:">
                    <el-input v-model="WorkaddForm.WorkAddress"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:">
                    <el-input v-model="WorkaddForm.LeavingReason"></el-input>
                </el-form-item>
                <el-form-item label="离职原因:">
                    <el-input type="textarea" v-model="WorkaddForm.BecaseWork"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话:">
                    <el-input v-model="WorkaddForm.WorkVoucher"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="WorkExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="WorkaddSubmit('WorkaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="家庭成员" :visible.sync="FamilyaddFormVisible" v-model="FamilyaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="FamilyaddForm" :model="FamilyaddForm">
                <el-form-item label="姓名:" prop="FaName">
                    <el-input v-model="FamilyaddForm.FaName"></el-input>
                </el-form-item>
                <el-form-item label="与本人关系:" prop="Relationship">
                    <el-input v-model="FamilyaddForm.Relationship"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:" prop="FaAge">
                    <el-input v-model="FamilyaddForm.WorkDpt"></el-input>
                </el-form-item>
                <el-form-item label="工作单位:" prop="WorkUnits">
                    <el-input v-model="FamilyaddForm.WorkUnits"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="PhoneNo">
                    <el-input v-model="FamilyaddForm.PhoneNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="FamilyaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="FamilyaddSubmit('FamilyaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <InterViewModal ref="InterViewModal" :InterviewForm="InterviewForm"></InterViewModal>
    </div>
</template>

<script>
    import util from "../../../util/date";
    import * as validate from "../../../util/validate";
    import {
        getUserBaseInfo,
        UpdateUserBaseInfo,
        getInterviewList,

    } from "../../api/hr";
    import InterViewModal from "../componentModals/InterViewModal"    // 面试
    export default {
        components: { InterViewModal },
        data() {
            return {
                token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
                dlgVisible: false,
                dlgFrom: {
                    DiseaseArr: [],
                    EducatedArr: [],
                    TrainedArr: [],
                    WorkedArr: [],
                    FamilyArr: [],
                },
                CustomeraddFormVisible: false,
                CustomeraddForm: [],
                TrainaddForm: [],
                TrainingExperienceaddFormVisible: false,
                WorkExperienceaddFormVisible: false,
                WorkaddForm: [],
                FamilyaddFormVisible: false,
                FamilyaddForm: [],

                InterviewRecord: [],

                userInterviewID: "",
                currentPage: 1, // 当前页码
                total: null, // 总条数
                totaldata: null,
                pageSize: 20, // 每页的数据条数
                page: 1,
                RecordcurrentPage: 1, // 面试记录当前页码
                Recordtotal: null, // 面试记录总条数
                Recordtotaldata: null,
                RecordpageSize: 20, // 面试记录每页的数据条数
                Recordpage: 1,


                InterviewForm: {
                    UserId: "",
                    UserName: "",
                    id: "",
                    factory: "",
                    post: "",
                    talkDate: new Date(),
                    isPassed: "",
                    userBaseInfoId: "",
                    factoryId: "",
                    MatchRate: "",
                    TalkPersons: "",
                    Recommendation: "",
                    BasicAbilityExp: "",
                    BasicAbility: "",
                    ProfessionalAbilityExp: "",
                    ProfessionalAbility: "",
                    OfficeAbilityExp: "",
                    OfficeAbility: "",
                    WorkAbility: "",
                    WorkAbilityExp: "",
                    Summarize: "",
                    Remark: "",
                    AttitudeExp: "",
                    Attitude: "",
                    DressUpExp: "",
                    DressUp: "",
                    PotentialAbilityExp: "",
                    PotentialAbility: "",
                    CommunicateAbilityExp: "",
                    CommunicateAbility: "",
                    ProfessionalSoftwareExp: "",
                    ProfessionalSoftware: "",
                },

                qyTypeOptions: [
                    {
                        value: "未婚",
                        label: "未婚",
                    },
                    {
                        value: "已婚",
                        label: "已婚",
                    },
                    {
                        value: "离异",
                        label: "离异",
                    },
                    {
                        value: "丧偶",
                        label: "丧偶",
                    },
                ],
                edTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "初中以下",
                        label: "初中以下",
                    },
                    {
                        value: "初中",
                        label: "初中",
                    },
                    {
                        value: "中专",
                        label: "中专",
                    },
                    {
                        value: "高中",
                        label: "高中",
                    },
                    {
                        value: "大专",
                        label: "大专",
                    },
                    {
                        value: "本科",
                        label: "本科",
                    },
                    {
                        value: "研究生",
                        label: "研究生",
                    },
                    {
                        value: "博士",
                        label: "博士",
                    },
                    {
                        value: "博士后",
                        label: "博士后",
                    },
                ],
                tiTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "助理工程师",
                        label: "助理工程师",
                    },
                    {
                        value: "中级工程师",
                        label: "中级工程师",
                    },
                    {
                        value: "高级工程师",
                        label: "高级工程师",
                    },
                    {
                        value: "初级会计师",
                        label: "初级会计师",
                    },
                    {
                        value: "中级会计师",
                        label: "中级会计师",
                    },
                    {
                        value: "高级会计师",
                        label: "高级会计师",
                    },
                    {
                        value: "初级经济师",
                        label: "初级经济师",
                    },
                    {
                        value: "中级经济师",
                        label: "中级经济师",
                    },
                    {
                        value: "高级经济师",
                        label: "高级经济师",
                    },
                ],
                jnTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "初级技工",
                        label: "初级技工",
                    },
                    {
                        value: "中级技工",
                        label: "中级技工",
                    },
                    {
                        value: "高级技工",
                        label: "高级技工",
                    },
                    {
                        value: "技师",
                        label: "技师",
                    },
                    {
                        value: "高级技师",
                        label: "高级技师",
                    },],
                poTypeOptions: [
                    {
                        value: "群众",
                        label: "群众",
                    },
                    {
                        value: "团员",
                        label: "团员",
                    },
                    {
                        value: "党员",
                        label: "党员",
                    },
                    {
                        value: "无党派人士",
                        label: "无党派人士",
                    },
                    {
                        value: "其他",
                        label: "其他",
                    },
                ],
                adTypeOptions: [
                    {
                        value: "本市城镇",
                        label: "本市城镇",
                    },
                    {
                        value: "本市农村",
                        label: "本市农村",
                    },
                    {
                        value: "外省/市城镇",
                        label: "外省/市城镇",
                    },
                    {
                        value: "外省/市农村",
                        label: "外省/市农村",
                    },
                ],
                applyTypeOptions: [
                    {
                        value: "58同城",
                        label: "58同城",
                    },
                    {
                        value: "门口招聘栏",
                        label: "门口招聘栏",
                    },
                    {
                        value: "本公司员工介绍",
                        label: "本公司员工介绍",
                    },
                    {
                        value: "智联招聘",
                        label: "智联招聘",
                    },
                    {
                        value: "校园招聘会",
                        label: "校园招聘会",
                    },
                    {
                        value: "格瑞德官网",
                        label: "格瑞德官网",
                    },
                    {
                        value: "BOSS直聘",
                        label: "BOSS直聘",
                    },
                    {
                        value: "微信公众号",
                        label: "微信公众号",
                    },
                    {
                        value: "其他",
                        label: "其他",
                    },
                ],
                OrganizeLists: [],
                positionLists: [
                    {
                        value: "技术",
                        label: "技术",
                    },
                    {
                        value: "后勤",
                        label: "后勤",
                    },
                    {
                        value: "生产",
                        label: "生产",
                    },
                    {
                        value: "管理",
                        label: "管理",
                    },
                    {
                        value: "特聘",
                        label: "特聘",
                    },
                ],
                passedLists: [
                    {
                        value: "通过",
                        label: "通过",
                    },
                    {
                        value: "未通过",
                        label: "未通过",
                    },
                ],
                EateSalaryLists: [
                    {
                        value: "5",
                        label: "5",
                    },
                    {
                        value: "25",
                        label: "25",
                    },
                    {
                        value: "30",
                        label: "30",
                    },
                ],
                CareDaySalaryLists: [
                    {
                        value: "5",
                        label: "5",
                    },
                    {
                        value: "10",
                        label: "10",
                    },
                ],
                OutsideSalaryLists: [
                    {
                        value: "300",
                        label: "300",
                    },
                    {
                        value: "500",
                        label: "500",
                    },
                ],
                ProbationExplainLists: [
                    {
                        value: "1",
                        label: "1",
                    },
                    {
                        value: "2",
                        label: "2",
                    },
                    {
                        value: "3",
                        label: "3",
                    },
                    {
                        value: "5",
                        label: "5",
                    },
                ],
                matchRateLists: [
                    {
                        value: "较高",
                        label: "较高",
                    },
                    {
                        value: "尚可",
                        label: "尚可",
                    },
                    {
                        value: "较低",
                        label: "较低",
                    },
                    {
                        value: "不符合",
                        label: "不符合",
                    },
                ],
                BasicLists: [
                    {
                        value: "较差",
                        label: "较差",
                    },
                    {
                        value: "一般",
                        label: "一般",
                    },
                    {
                        value: "良好",
                        label: "良好",
                    },
                    {
                        value: "较好",
                        label: "较好",
                    },
                    {
                        value: "优秀",
                        label: "优秀",
                    },
                ],
                RecommendationLists: [
                    {
                        value: "强烈推荐",
                        label: "强烈推荐",
                    },
                    {
                        value: "可安排复试",
                        label: "可安排复试",
                    },
                    {
                        value: "资料存档",
                        label: "资料存档",
                    },
                    {
                        value: "不符合",
                        label: "不符合",
                    },
                ],
                rules: {
                    factory: [
                        { required: true, message: "请选择面试公司", trigger: "blur" },
                    ],
                    talkDate: [{ required: true, message: "请选择日期", trigger: "blur" }],
                    post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
                    isPassed: [
                        { required: true, message: "请选择是否通过", trigger: "blur" },
                    ],
                    PostType: [
                        { required: true, message: "请选择岗位类型", trigger: "blur" },
                    ],
                    TalkPersons: [
                        { required: true, message: "请选择面试人员", trigger: "blur" },
                    ],
                    BasicAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    ProfessionalAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    WorkAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    OfficeAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    PotentialAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    DressUpExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    Summarize: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    OldFactoryWelfare: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    AttitudeExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    ProfessionalSoftwareExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                    CommunicateAbilityExp: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                    ],
                },
                postRules: {
                    Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
                    StartDate: [
                        { required: true, message: "请选择开始时间", trigger: "blur" },
                    ],
                    ProbationExplain: [
                        { required: true, message: "请选择试用期", trigger: "blur" },
                    ],
                    PositionName: [
                        { required: true, message: "请选择职务待遇", trigger: "blur" },
                    ],
                    SalaryTypeName: [
                        { required: true, message: "请选择工资类型", trigger: "blur" },
                    ],
                },
            };
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        DiseaseArr: [],
                        EducatedArr: [],
                        TrainedArr: [],
                        WorkedArr: [],
                        FamilyArr: [],
                    };
                    this.dlgVisible = true;
                }
                else {
                    getUserBaseInfo({ id: pid }).then((res) => {
                        this.dlgFrom = res.data.response;
                        getInterviewList({ userBaseInfoId: this.dlgFrom.Id }).then((res) => {
                            this.InterviewRecord = res.data.response;
                        });
                    });

                    this.dlgVisible = true;
                }
            },

            FamilyDelete(row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.FamilyArr.splice(this.dlgFrom.FamilyArr.indexOf(row), 1);
                });
            },
            WorkExperienceDelete(row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.WorkedArr.splice(this.dlgFrom.WorkedArr.indexOf(row), 1);
                });
            },
            TrainingExperienceDelete(row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.TrainedArr.splice(this.dlgFrom.TrainedArr.indexOf(row), 1);
                });
            },
            CustomerhandleDelete(row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.dlgFrom.EducatedArr.splice(this.dlgFrom.EducatedArr.indexOf(row), 1);
                });
            },
            print() {
                this.$print(this.$refs.print);
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.Func].apply(this, item);
            },


            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },

            Print() {
                let row = this.currentRow;
                if (!row) {
                    this.$router.push({ path: "/printUser", query: { id: 0 } });
                    return;
                }
                this.$router.push({ path: "/printUser", query: { id: row.Id } });
            },



            //头像上传成功
            OSOnSuccess(response, file, fileList) {
                this.dlgFrom.Image_URL =
                    response.data.httpHost + response.data.filePath;
            },
            //头像上传附件数量控制
            OSFileexceed(files, fileList) {
                this.$message({
                    message: "当前上传最大数量为1个。",
                    type: "warning",
                });
            },
            submitForm(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    let para = Object.assign({}, this.dlgFrom);
                    console.log(this.dlgFrom);
                    UpdateUserBaseInfo(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.dlgVisible = false;

                        }
                    });
                });
            },

            resetForm(formName) {
                this.dlgVisible = false;
            },
            phonecheck() {
                if (!validate.isMobile(this.form.MobilePhone)) {
                    this.$message({
                        message: "该输入无效,请输入其他有效手机号",
                        type: "error",
                        duration: 5000,
                    });
                    this.form.MobilePhone = null;
                    return;
                }
            },
            nocheck() {
                if (!validate.validateIdNo(this.form.idCard)) {
                    this.$message({
                        message: "该输入无效,请输入有效身份证号",
                        type: "error",
                        duration: 5000,
                    });
                    this.form.idCard = null;
                    return;
                }
            },
            //学习经历添加
            customerhandleAdd() {
                this.CustomeraddFormVisible = true;
                this.CustomeraddForm = {
                    EnterTime: null,
                    GraduateTime: null,
                    GradauteSchool: null,
                    Speciality: null,
                    Certificate: null,
                    Voucher: null,
                };
                this.$nextTick(() => {
                    this.$refs.CustomeraddForm.clearValidate();
                });
            },
            CustomerhandleDetail(row, index) {
                this.CustomeraddForm = Object.assign({}, row);
                this.CustomeraddForm.rowIndex = index;
                this.CustomeraddFormVisible = true;
            },
            //学习经历添加提交
            CustomeraddSubmit(formName) {
                let _this = this;
                this.$refs.CustomeraddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.CustomeraddForm);
                        if (this.CustomeraddForm.rowIndex != undefined) {
                            this.$set(
                                this.dlgFrom.EducatedArr,
                                this.CustomeraddForm.rowIndex,
                                para
                            );
                        } else {
                            if (this.dlgFrom.EducatedArr == null) {
                                this.dlgFrom.EducatedArr = [];
                            }
                            this.dlgFrom.EducatedArr.push(para);
                        }

                        this.CustomeraddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },

            // 培训经历
            trainAdd() {
                this.TrainingExperienceaddFormVisible = true;

                this.TrainaddForm = {
                    StartTime: null,
                    EndTime: null,
                    Institutions: null,
                    Details: null,
                    InCertificate: null,
                };
                this.$nextTick(() => {
                    this.$refs.TrainaddForm.clearValidate();
                });
            },
            TrainingExperienceDetail(row, index) {
                this.TrainaddForm = Object.assign({}, row);
                this.TrainaddForm.rowIndex = index;
                this.TrainingExperienceaddFormVisible = true;
            },
            TrainaddSubmit(formName) {
                let _this = this;

                let para = Object.assign({}, this.TrainaddForm);
                console.log(this.TrainaddForm);
                if (this.TrainaddForm.rowIndex != undefined) {
                    this.$set(this.dlgFrom.TrainedArr, this.TrainaddForm.rowIndex, para);
                } else {
                    if (this.dlgFrom.TrainedArr == null) {
                        this.dlgFrom.TrainedArr = [];
                    }
                    this.dlgFrom.TrainedArr.push(para);
                }

                this.TrainingExperienceaddFormVisible = false;
            },
            // 工作经历
            workAdd() {
                this.WorkExperienceaddFormVisible = true;
                this.WorkaddForm = {
                    StartTime: null,
                    EndTime: null,
                    WorkAddress: null,
                    LeavingReason: null,
                    BecaseWork: null,
                    WorkVoucher: null,
                };
                this.$nextTick(() => {
                    this.$refs.WorkaddForm.clearValidate();
                });
            },
            WorkExperienceDetail(row, index) {
                this.WorkaddForm = Object.assign({}, row);
                this.WorkaddForm.rowIndex = index;
                this.WorkExperienceaddFormVisible = true;
            },
            WorkaddSubmit(formName) {
                let _this = this;
                this.$refs.WorkaddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.WorkaddForm);
                        if (this.WorkaddForm.rowIndex != undefined) {
                            this.$set(
                                this.dlgFrom.WorkedArr,
                                this.WorkaddForm.rowIndex,
                                para
                            );
                        } else {
                            if (this.dlgFrom.WorkedArr == null) {
                                this.dlgFrom.WorkedArr = [];
                            }
                            this.dlgFrom.WorkedArr.push(para);
                        }

                        this.WorkExperienceaddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },

            // 家庭成员
            familyAdd() {
                this.FamilyaddFormVisible = true;
                this.FamilyaddForm = {
                    FaName: null,
                    Relationship: null,
                    WorkDpt: null,
                    WorkUnits: null,
                    PhoneNo: null,
                };
                this.$nextTick(() => {
                    this.$refs.FamilyaddForm.clearValidate();
                });
            },
            FamilyDetail(row, index) {
                this.FamilyaddForm = Object.assign({}, row);
                this.FamilyaddForm.rowIndex = index;
                this.FamilyaddFormVisible = true;
            },
            FamilyaddSubmit(formName) {
                let _this = this;
                this.$refs.FamilyaddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.FamilyaddForm);
                        if (this.FamilyaddForm.rowIndex != undefined) {
                            this.$set(
                                this.dlgFrom.FamilyArr,
                                this.FamilyaddForm.rowIndex,
                                para
                            );
                        } else {
                            if (this.dlgFrom.FamilyArr == null) {
                                this.dlgFrom.FamilyArr = [];
                            }
                            this.dlgFrom.FamilyArr.push(para);
                        }

                        this.FamilyaddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },
            onInterViewdblclick(row) {
                this.InterviewForm = row;
                this.$refs.InterViewModal.onShowDlg();
            }
        },
        mounted() {

        },
    };
</script>

<style scoped>
</style>